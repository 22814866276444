import React from 'react';
import './app.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Flight from './Components/Flight/Flight';
import Hotel from './Components/Hotel/Hotel';
import Package from './Components/Package/Package';
import Main from './Components/Main/Main';
import Mainhotel from './Components/Hotel/Mainhotel';
import Mainpackage from './Components/Package/Mainpackage';
import Footer from './Components/Footer/Footer';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

const SlugPage = () => {
  const { slug } = useParams();
  
  if (slug === 'flight') return <div><Flight /><Main /></div>;
  if (slug === 'hotel') return <div><Hotel /><Mainhotel /></div>;
  if (slug === 'package') return <div><Package /><Mainpackage /></div>;

  // Add more conditions for different slugs as needed
  return <div>Page Not Found</div>; // Fallback for undefined slugs
};

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<div><Home /><Main /></div>} />
        <Route path="/flight" element={<div><Flight /><Main /></div>} />
        <Route path="/hotel" element={<div><Hotel /><Mainhotel /></div>} />
        <Route path="/package" element={<div><Package /><Mainpackage /></div>} />
        
        {/* Dynamic route to handle SEO-friendly slugs */}
        <Route path="/:slug" element={<SlugPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
