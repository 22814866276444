import React, {useState} from 'react'
import './navbar.css'
import {MdOutlineTravelExplore} from 'react-icons/md'
import {AiFillCloseCircle} from 'react-icons/ai'
import {TbGridDots} from 'react-icons/tb'
import { NavLink } from 'react-router-dom'
import { PiAirplaneTakeoffLight } from "react-icons/pi";


const Navbar = () => {
    const [active, setActive] = useState('navBar')
    const showNav = ()=>{
        setActive('navBar activeNavbar')
    }
    const removeNavbar = ()=>{
        setActive('navBar')
    }
  return (
    <selection className='navBarSection'>
        <header className='header flex'>
            <div className="logoDiv">
            <NavLink to="/"><a href="#" className='logo' onClick={removeNavbar}>
                    <h1 style={{ fontSize: '24px' }}><PiAirplaneTakeoffLight style={{ color: 'hsl(199, 100%, 33%)' }} /><sup style={{ fontSize: '14px' }}>New</sup>
Flight Booking.</h1>
                </a>
            </NavLink>
            </div>

            <div className={active}>
                <ul className="navLists flex">
                    <li className="navItem">
                    <NavLink to="/"><a href=" " className="navLink" onClick={removeNavbar}>Home</a></NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/flight"><a href=" " className="navLink" onClick={removeNavbar}>Flight</a></NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/hotel">
                    <a href=" " className="navLink" onClick={removeNavbar}>Hotel</a>
                    </NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/package">
                    <a href=" " className="navLink" onClick={removeNavbar}>Package</a>
                    </NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/about">
                    <a href=" " className="navLink"onClick={removeNavbar} >About</a>
                    </NavLink>
                    </li>
                    <button className='btn'>
                        <a href="tel:+1(888)-724-5231">Contact Us</a>
                    </button>
                </ul>

                <div onClick={removeNavbar} className="closeNavbar">
                <AiFillCloseCircle className="icon"/>
                </div>
            </div>

            <div onClick={showNav} className="toggleNavbar">
            <TbGridDots className="icon"/>
            </div>

        </header>
    </selection>
  )
}


export default Navbar
