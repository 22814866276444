import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './home.css';
import './sug.css';
import video from '../../Assets/home.mp4';
import { GrLocation } from 'react-icons/gr';
import { FiFacebook } from 'react-icons/fi';
import { AiOutlineInstagram } from 'react-icons/ai';
import { SiTripadvisor } from 'react-icons/si';
import { BsListTask } from 'react-icons/bs';
import { TbApps } from 'react-icons/tb';
import { BiPhoneCall } from "react-icons/bi";
import Aos from 'aos';
import 'aos/dist/aos.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import suggestionsList from './sug';
import { AiFillCloseCircle } from 'react-icons/ai';

const Home = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [toFieldDisabled, setToFieldDisabled] = useState(true);
  const [flightVisible, setFlightVisible] = useState(true);
  const [hotelVisible, setHotelVisible] = useState(false);
  const [packageVisible, setPackageVisible] = useState(false);
  const [showEntryPopup, setShowEntryPopup] = useState(false); // For the full-screen mobile popup on entry
  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false); // For the form submission popup

  useEffect(() => {
    Aos.init({ duration: 2000 });
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      setShowEntryPopup(true); // Show the entry popup on mobile devices
    }
  }, []);


    // Function to check if the device is iOS
    const isIOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : suggestionsList.filter((suggestion) =>
          inputLength <= 3
            ? suggestion.toLowerCase().slice(0, inputLength) === inputValue
            : suggestion.toLowerCase().includes(inputValue)
        );
  };

  const onSuggestionSelected = (field, suggestion) => {
    if (field === 'from') {
      setFromValue(suggestion);
      setFromSuggestions([]);
      setToFieldDisabled(false);
      setToValue('');
      setEndDate(null);
    } else {
      setToValue(suggestion);
      setToSuggestions([]);
    }
  };

  const onInputChange = (field, value) => {
    if (field === 'from') {
      setFromValue(value);
      setFromSuggestions(getSuggestions(value));
    } else {
      setToValue(value);
      setToSuggestions(getSuggestions(value));
    }
  };

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const handleFlightSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      from: fromValue,
      to: toValue,
      startDate: startDate,
      endDate: endDate,
      name: e.target.elements.flightName.value,
      phone: e.target.elements.flightPhone.value
    };
    console.log("Flight form submitted:", formData);
    try {
      const response = await emailjs.send('service_9ofoktd', 'template_0zl2b9b', formData, 'PyRb6GjhzSTp12X4A');
      console.log('Form submitted successfully', response);
      setShowSubmissionPopup(true); // Show the submission popup on successful form submit
      setFromValue('');
      setToValue('');
      setStartDate(null);
      setEndDate(null);
      e.target.elements.flightName.value = '';
      e.target.elements.flightPhone.value = '';
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const toggleFlight = () => {
    setFlightVisible(true);
    setHotelVisible(false);
    setPackageVisible(false);
  };

  const toggleHotel = () => {
    setHotelVisible(true);
    setFlightVisible(false);
    setPackageVisible(false);
  };

  const togglePackage = () => {
    setPackageVisible(true);
    setFlightVisible(false);
    setHotelVisible(false);
  };

  return (
    <section className='home'>
      <div className='overlay'></div>
      <video src={video} muted autoPlay={!isIOS()} playsInline loop type='video/mp4'></video>
      
      <div className='homeContent container'>
        <div className='textDiv'>
          <span data-aos='fade-up' className='smallText'>
            Fly Now! Book with 30% Off Today!
          </span>
          <h1 data-aos='fade-up' className='homeTitle'>
            Fast, Easy, and Affordable Flight Ticket Booking
          </h1>
        </div>

        <div data-aos='fade-up' className="buttonGroup">
          <button className="btn btngrp" onClick={toggleFlight}>Flight</button>
          <button className="btn btngrp" onClick={toggleHotel}>Hotel</button>
          <button className="btn btngrp" onClick={togglePackage}>Package</button>
        </div>

        {/* Flight Form */}
        {flightVisible && (
          <form onSubmit={handleFlightSubmit}>          
            <div data-aos='fade-up' className='cardDiv grid'>
              <div className='destinationInput'>
                <label className='city'>From:</label>
                <div className='input flex'>
                  <div className="autosuggest-container">
                    <input
                      type="text"
                      value={fromValue}
                      onChange={(e) => onInputChange('from', e.target.value)}
                      placeholder="Departure City" required 
                    />
                    {fromSuggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {fromSuggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => onSuggestionSelected('from', suggestion)}>
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <GrLocation className='icon' />
                </div>
              </div>
              <div className='destinationInput'>
                <label className='city'>To:</label>
                <div className='input flex'>
                  <div className="autosuggest-container">
                    <input
                      type="text"
                      value={toValue}
                      onChange={(e) => onInputChange('to', e.target.value)}
                      placeholder="Arrival City" disabled={toFieldDisabled} required 
                    />
                    {toSuggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {toSuggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => onSuggestionSelected('to', suggestion)}>
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <GrLocation className='icon' />
                </div>
              </div>
              <div className='dateInput'>
                <label htmlFor='startDate'>Select your dates:</label>
                <div className='input flex'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat='MMM-dd-yyyy'
                    placeholderText='Departure date' required 
                    minDate={getToday()}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat='MMM-dd-yyyy'
                    placeholderText='Return date'
                    minDate={startDate}
                  />
                </div>
              </div>
              <div className='destinationInput'>
                <label className='city'>Name:</label>
                <div className='input flex'>
                  <input id="flightName" type='text' placeholder='Enter Your Name ' required />
                  <GrLocation className='icon' />
                </div>
              </div>
              <div className='destinationInput'>
                <label className='city'>Phone:</label>
                <div className='input flex'>
                  <input id="flightPhone" type='number' name='fphone' placeholder='Enter Phone number' required />
                  <GrLocation className='icon' />
                </div>
              </div>
              <div className='searchOptions flex'>
                <button className='btn' style={{color:'white'}}>Search</button>
              </div>
            </div>
          </form>
        )}

        {/* Full-screen mobile entry popup */}
{/* Full-screen mobile entry popup */}
{showEntryPopup && (
  <div className="mobile-popup">
    <div className="popup-content">
      {/* 2x3 Button Grid */}

      <div className="popup-buttons-grid">
        <button className="popup-btn"><a href="tel:+1-888-724-5231" style={{color: 'white'}}>New Bookings</a></button>
        <button className="popup-btn"><a href="tel:+1-888-724-5231" style={{color: 'white'}}>Changes</a></button>
        <button className="popup-btn"><a href="tel:+1-888-724-5231" style={{color: 'white'}}>Cancellations</a></button>
        <button className="popup-btn"><a href="tel:+1-888-724-5231" style={{color: 'white'}}>Customer Service</a></button>
        <button className="popup-btn"><a href="tel:+1-888-724-5231" style={{color: 'white'}}>Refunds</a></button>
        <button className="popup-btn"><a href="tel:+1-888-724-5231" style={{color: 'white'}}>Upgrades</a></button>
      </div>

      {/* Info Section */}
      <div className="popup-info">
        <p>Booking & changes over the phone</p>
        <p>Flexible payment & price match guarantee</p>
      </div>

      {/* Profile Picture and Phone Info */}
      <div className="popup-profile">
        <img src="https://www.shutterstock.com/image-photo/contact-call-center-service-customer-260nw-2453079477.jpg" alt="Profile" className="profile-pic" />
        <p style={{color:'black'}}>No hold - calls answered in 5 seconds</p>
        <button className="btn call-btn">
          <a href="tel:+1-888-724-5231">+1 888 724 5231 <span style={{display: 'block', fontSize: '0.9em', color: 'white'}}>Unpublished phone-only offers</span></a>
        </button>
      </div>

      {/* Close Button at Bottom */}
      <button onClick={() => setShowEntryPopup(false)} className="close-button">
        Continue
      </button>
    </div>
  </div>
)}




        {/* Submission popup */}
        {showSubmissionPopup && (
          <div className="popup">
            <div onClick={() => setShowSubmissionPopup(false)} className="closeNavbar">
              <AiFillCloseCircle className="icon"/>
            </div>            
            <div className="popup-inner">
              <img src="https://wynva.com/Wynva_ads.jpg" alt="Flight" />
              <div className="buttonGroup">
                <button className="btn" onClick={() => setShowSubmissionPopup(false)}>
                  <a href="tel:+1(888)-724-5231">Continue</a>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn" onClick={() => setShowSubmissionPopup(false)}>
                  <a href="tel:+1(888)-724-5231">Call Now</a>
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Footer with Call Now button */}
        <div data-aos='fade-right' style={{background:'White',borderRadius:'24px',padding:'20px'}} className='homeFooterIcons flex'>
          <div className='rightIcons'>
            <h3 style={{color: 'hsl(199, 100%, 33%)'}} className="title">
              Call Now for Exclusive Flight Ticket Deals! 
            </h3>
          </div>
          <div className='leftIcons'>
            <button className='btn' style={{whiteSpace: 'nowrap'}}>
              <a href="tel:+1(888)-724-5231"><BiPhoneCall style={{fontSize:'1.8vh'}}/><span style={{textAlign:'center',fontSize:'1.8vh',color:'white'}}>Call Now</span></a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
